import { pushWebCallback } from '~/plugins/gtm.client';
import {
  FREESPEE_SET_CALLBACKS,
  LAYOUT_SET_IADVIZE_FLAG,
} from '~/store/mutationTypes';
import { setFreespeeDynamicData } from '~/utils/freespee/get-freespee-callbacks';
import handleFreespeeUpdate from '~/utils/freespee/set-freespee-params';

import { ref, watch } from 'vue';
export default () => {
  const context = useNuxtApp();
  const hasIadvize = ref(false);
  const dataClone = ref(null);
  const phone = ref(null);
  const isNowClient = ref(false);
  const freespee = ref({
    hasError: false,
    isLoading: false,
  });

  const freespeeStaticData = computed(() => {
    return context.$store.state.freespee.data.static;
  });
  const freespeeDynamicData = computed(() => {
    return context.$store.state.freespee.data.dynamic;
  });
  const freespeeCallbacks = computed(() => {
    return context.$store.state.freespee.callbacks;
  });
  const freespeeCustomerId = computed(() => {
    return context.$store.state.freespee.data.static.customerId;
  });

  function handleWebcallbackMounted() {
    window.__fs_dncs_instance.trackPage(window.location.href);
  }
  function checkIadvizeImplementation() {
    // If iAdvize window object, set vuex state
    const interval = setInterval(() => {
      if (window.iAdvize) {
        context.$store.commit(`layout/${LAYOUT_SET_IADVIZE_FLAG}`, true);
        clearInterval(interval);
      }
    }, 200);
    // Since we don't know if iAdvize will be available, we need to have a timeout
    setTimeout(() => {
      clearInterval(interval);
    }, 5000);
  }

  async function handleWebcallbackLoad() {
    freespee.value.isLoading = true;
    try {
      const { freespeeCustomerId: customerId } = context;
      if (customerId === undefined) {
        throw new Error('Failed to request Freespee : missing contribution');
      }

      const res = await useFetch(
        `/api/freespee/get/?customer_id=${customerId}`
      );
      if (!res.ok) {
        console.warn('Failed to request Freespee');
        return;
      }

      const freespee = await res.json();
      context.$store.commit(
        `freespee/${FREESPEE_SET_CALLBACKS}`,
        freespee.callbacks
          .filter((o) => o.completed === '0' && o.scheduled !== null)
          .map((o) => {
            const isoString = `${o.scheduled.split(' ').join('T')}Z`;
            return new Date(isoString).getTime();
          })
      );
    } catch (error) {
      freespee.value.hasError = true;
      console.error(error);
    }

    freespee.value.isLoading = false;
  }
  function handleInputValue(value) {
    if (value.length > 13 && this.phone != value) {
      const userPhoneNumber = `+33${value.replace(/ /g, '').slice(1)}`;
      fetch(`/phonetest?tel=${userPhoneNumber.toString()}`, {
        method: 'post',
      }).then((testPhone) => {
        testPhone.json().then((isClient) => {
          this.isNowClient = isClient.isClient;
        });
      });
    }
    if (value.length > 13) {
      this.phone = value;
    }
    this.dataClone = {
      ...this.freespeeStaticData,
      ...this.freespeeDynamicData,
    };
  }
  async function handleWebcallbackSubmit(payload) {
    freespee.value.isLoading = true;
    const { freespeeCustomerId: customerId } = context;
    if (customerId === undefined) {
      throw new Error('Failed to request Freespee : missing contribution');
    }
    const {
      date: scheduled,
      userPhoneNumber: requester_nr,
      agentPhoneNumber: agent_nr,
    } = payload;
    const data = { scheduled, requester_nr, agent_nr };

    const searchParams = new URLSearchParams();
    searchParams.set('customer_id', customerId);
    searchParams.set('retries', 3);
    Object.keys(data)
      .filter((key) => data[key])
      .forEach((key) => {
        searchParams.set(key, data[key]);
      });

    const res = await useFetch(
      `/api/freespee/post?${searchParams.toString()}`,
      {
        method: 'post',
      }
    );
    const json = await res.json();
    if (
      json &&
      json[0] &&
      json[0].callbacks &&
      json[0].callbacks.requester_nr
    ) {
      pushWebCallback(context);
    }
    if (!res.ok) {
      console.warn('Failed to request Freespee');
    }
    freespee.value.isLoading = false;
  }
  function getFreespeeData(slice) {
    const { freespeeParams } = slice?.data;
    if (freespeeParams) handleFreespeeUpdate(freespeeParams);
  }
  function updateFreespeeData(freespeeStaticData) {
    if (freespeeStaticData) {
      setFreespeeDynamicData(
        freespeeStaticData?.openingHours,
        freespeeStaticData?.closedDays,
        context.$store
      );
    }
  }

  onMounted(() => {
    updateFreespeeData(freespeeStaticData);
    checkIadvizeImplementation();
  });

  watch(freespeeStaticData, (updatedfreespeeStaticData) => {
    updateFreespeeData(updatedfreespeeStaticData);
  });

  return {
    freespeeStaticData,
    freespeeDynamicData,
    hasIadvize,
    freespee,
    freespeeCallbacks,
    freespeeCustomerId,
    handleWebcallbackMounted,
    handleWebcallbackLoad,
    handleInputValue,
    handleWebcallbackSubmit,
    getFreespeeData,
    updateFreespeeData,
  };
};
